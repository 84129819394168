import React from "react";

import useUserData from "../../hooks/useUserData";
import {Alert, Button, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import utils from "../../components/utils";
import TypeSelector from "./components/TypeSelector";
import EventSelector from "../../components/EventSelector";


export default function FormPage(props) {
    document.title = "Karaoke in Movimento - Nuovo"
    const user = useUserData()
    const navigate = useNavigate()

    const [showAlert, setShowAlert] = React.useState(false)

    const [event, setEvent] = React.useState(1)
    const [title, setTitle] = React.useState("")
    const [author, setAuthor] = React.useState("")
    const [dedication, setDedication] = React.useState("")
    const [tono, setTono] = React.useState("")
    const [type, setType] = React.useState(1)

    React.useEffect(() => {
        if (!user.logged) {
            navigate("/")
        }
    }, [navigate, user.logged])

    const clearModel = () => {
        setTitle("")
        setAuthor("")
        setDedication("")
        setTono("")
    }

    const handleSubmit = () => {
        const headers = {authorization: `Bearer ${user.token}`}
        const model = {
            event,
            title,
            author,
            dedication,
            tono,
            type
        }
        utils.apiPost("/request/add", model, headers)
            .then(r => {
                clearModel()
                setShowAlert(true);
            })
    }
    return <>
        <div className="container-page">
            {showAlert && <Alert severity="success" onClose={() => {
                setShowAlert(false)
            }}>Aggiunto con successo</Alert>}
            <Typography variant="h3" fontSize={30} fontWeight={900}
                        className="benvenuto"><span>Benvenuto</span> {user.username}</Typography>
            <div className="container-form">
                <div className="type-box-100">
                        <EventSelector
                            variant="filled"
                            name="event"
                            label="In che locale ti trovi?"
                            fullWidth
                            required
                            value={event}
                            onChange={({target}) => {
                                setEvent(target.value)
                            }}/>
                </div>
                    <div className="type-box-100">
                        <TextField
                            variant="filled"
                            id="title"
                            label="Titolo"
                            fullWidth
                            required
                            value={title}
                            onChange={({target}) => {
                                setTitle(target.value)
                            }}
                        />
                    </div>
                    <div className="type-box-100">
                        <TextField
                            variant="filled"
                            id="author"
                            label="Autore"
                            fullWidth
                            required
                            value={author}
                            onChange={({target}) => {
                                setAuthor(target.value)
                            }}
                        />
                    </div>
                    <div className="type-box-100">
                        <TextField
                            variant="filled"
                            id="dedication"
                            label="Dedica"
                            fullWidth
                            value={dedication}
                            onChange={({target}) => {
                                setDedication(target.value)
                            }}
                        />
                    </div>
                    <div className="type-box-100">
                        <TextField
                            variant="filled"
                            id="tono"
                            label="Tonalità"
                            fullWidth
                            value={tono}
                            onChange={({target}) => {
                                setTono(target.value)
                            }}
                        />
                    </div>
                {/* <div className="type-box-100">
                        <TypeSelector
                            variant="filled"
                            name="type"
                            label="Cosa vuoi fare?"
                            fullWidth
                            required
                            value={type}
                            onChange={({target}) => {
                                setType(target.value)
                            }}/>
                    </div> */}
                    <Button disabled={!event || !title || !author || !type} className="button-invia" variant="contained"
                            onClick={handleSubmit}>Invia</Button>
                </div>
            </div>
    </>
}